@import "variables";
@import "mixins/breakpoints";
@import "mixins/clearfix";
@import "mixins/placeholder-color";
@import "mixins/shapes";

body {
  background-color: transparent;
}

.app-layout {
  display: block;
}

.main-content {
  max-height: none;
  overflow-y: auto;

  &.mod-blue {
    background-color: transparent;
  }
}

.sidebar,
.mini-sidebar,
.env-disclaimer,
.flash-message-list,
.project-nav-container,
.custom-view-select-container,
.project-navbar,
.project-nav {
  display: none;
}

.project-pdf-report-milestone-table {
  font-size: 11px;
}
